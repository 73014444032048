import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import LeftImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeLeftSide.svg';
import RightImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeRightSide.svg';
import ThankYouImg from '../../../../common/assets/image/saasModern/ThankYou.png';
import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebsiteFormEnrichmentThankYouPage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img =
    ZoominfoPageData.allStrapiWebsiteFormEnrichmentThankYouPage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <img className='left-bg' src={LeftImg} alt='Left Background' />
      <img className='right-bg' src={RightImg} alt='Right Background' />

      <div className='text'>
        <h1>
          <span style={{ color: '#03BAED' }}>Thank you</span>
          <br />
          for requesting
        </h1>
        <hr className='hr' />
        <h2>Free Database</h2>

        <p>Your email address will soon receive the desired database link.</p>
      </div>

      <img className='right-img' src={ThankYouImg} />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
