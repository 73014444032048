import styled from 'styled-components';
const ZoomInfoHeaderWrapper = styled.section`
  position: relative;
  display: flex;
  margin: 80px auto 40px;
  align-items: center;
  justify-content: space-evenly;
  color: black;
  padding: 20px;

  .left-bg {
    position: absolute;
    top: -20px;
    left: 0;
    z-index: -1;
    width: 47rem; /* Adjust the width of the background image */
    height: auto; /* Maintain aspect ratio */
  }

  .right-bg {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: -1;
    width: 53rem;
    height: auto;
  }

  .right-img {
    margin-left: -50px;
    width: 450px; /* Adjust the width of the image */
    height: auto; /* Maintain aspect ratio */
    @media screen and (max-width: 435px) {
      width: 250px; /* Adjust the width of the image */
      height: auto;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 18rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 60%;
      height: 35px;
    }
  }

  .text {
    width: 55%;
    margin-top: -10px;
    text-align: left;
    position: relative;

    h1 {
      color: #000;
      font-family: Poppins;
      font-size: 55px;
      font-style: normal;
      font-weight: 600;
      /* line-height: 55px; */
      margin-bottom: -5px;
    }

    h2 {
      color: #000;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      margin-top: -2px;
    }

    .hr {
      width: 25rem;
      margin-left: 2px;
      /* height: 2px; */
      color: black;
      /* height: 4px; */

      @media screen and (max-width: 435px) {
        width: 20rem;
      }
    }

    p {
      color: #767676;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      padding: 0px 110px 0px 0px;
    }
    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 45px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 60%;
    }
  }
`;

export default ZoomInfoHeaderWrapper;
